<template>
  <component v-if="errorComponent" :is="errorComponent" />
  <div v-else class="wrapper" :class="{ 'isHome': this.$route.name === 'home' }">
    <navbar @downloadApp="downloadApp++" />
    <transition mode="out-in" name="custom-classes-transition" enter-active-class="fade-in" leave-active-class="fade-out">
      <router-view :downloadApp="downloadApp" />
    </transition>
    <app-footer />
    <update-modal></update-modal>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar'
import AppFooter from '@/components/AppFooter'
import UpdateModal from '@/components/UpdateModal'
import { mapGetters } from 'vuex'
import Error404 from '@/views/Error404'
import Error500 from '@/views/Error500'

export default {
  name: 'app',
  components: {
    Navbar,
    AppFooter,
    UpdateModal,
    Error404,
    Error500
  },
  data () {
    return {
      downloadApp: 0,
      errorComponent: null
    }
  },
  computed: {
    ...mapGetters(['errorPage'])
  },
  created () {
    if (this.$route.meta && this.$route.meta.error) this.errorComponent = 'Error404'
  },
  watch: {
    'errorPage' (val) {
      this.errorComponent = val
    },
    '$route.meta.error' (val) {
      if (val) this.errorComponent = 'Error404'
    }
  }
}
</script>
