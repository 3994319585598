<template>
  <header :class="{ 'opaqueHeader': scrollPos > 10 }">
    <div class="container">
      <div class="row">
        <div class="col btn-menu">
          <img :src="baseImgUrl + 'bt_menu@2x.png'" alt="Menu" @click="openMobileMenu = true" />
        </div>
        <div v-if="!logoInicialFalse || scrollPos > 10" class="col">
          <router-link class="logo" :to="{ name: 'home' }">
            <img :src="baseImgUrl + 'h_negative@2x.png'" alt="Heppi" />
          </router-link>
        </div>
        <div class="col col-auto">
          <nav class="mobile" :class="{ 'opened': openMobileMenu }" @click="openMobileMenu = false">
            <ul>
              <router-link class="nav-logo" :to="{ name: 'home' }">
                <img :src="baseImgUrl + 'heppi.png'" :srcset="baseImgUrl + 'heppi@2x.png 2x'" alt="Heppi" />
              </router-link>
              <li><router-link exact-active-class="active" :to="{ name: 'about' }">Como funciona</router-link></li>
              <!-- <li><router-link exact-active-class="active" :to="{ name: 'pricing' }">Quanto custa</router-link></li> -->
              <li><a href="https://fatura.heppi.io" target="_blank">Acessar fatura</a></li>
              <!--<li><router-link exact-active-class="active" :to="{ name: 'partners' }">Seja parceiro</router-link></li>-->
            </ul>
          </nav>
          <nav class="top">
            <ul>
              <li><router-link exact-active-class="active" :to="{ name: 'about' }">Como funciona</router-link></li>
              <!-- <li><router-link exact-active-class="active" :to="{ name: 'pricing' }">Quanto custa</router-link></li> -->
              <!--<li><router-link exact-active-class="active" :to="{ name: 'partners' }">Seja parceiro</router-link></li>-->
            </ul>
          </nav>
        </div>
        <div class="col">
          <nav class="top">
            <ul>
              <li><a href="https://fatura.heppi.io" target="_blank">Acessar fatura</a></li>
            </ul>
          </nav>
        </div>
        <div class="col">
          <button class="btn btn-negative-primary my-1" @click="$router.push({ name: 'home', hash: '#download-app' }); $emit('downloadApp')">Baixe o app</button>
        </div>
      </div>
    </div>
  </header>  
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: "navbar",
  data () {
    return {
      scrollPos: 0,
      openMobileMenu: false
    }
  },
  computed: {
    ...mapGetters(['baseImgUrl']),
    logoInicialFalse () {
      return (this.$route.meta && this.$route.meta.logoInicialFalse) ? true : false
    },
    platformLink () {
      return (process.env.VUE_APP_PLATFORM) ? process.env.VUE_APP_PLATFORM : false
    }
  },
  created () {
    this.scrollPos = window.pageYOffset || document.documentElement.scrollTop
    document.addEventListener('scroll', ()=>{
      this.scrollPos = window.pageYOffset || document.documentElement.scrollTop
    }, false)
  }
}
</script>
