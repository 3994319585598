import Vue from "vue"
import Router from "vue-router"

import Home from "./views/Home.vue"
import About from "./views/About.vue"
import Pricing from "./views/Pricing.vue"
import Partners from "./views/Partners.vue"
import FAQ from "./views/FAQ.vue"
import Contacts from "./views/Contacts.vue"
import UseTerms from "./views/UseTerms.vue"
import PrivacyPolicy from "./views/PrivacyPolicy.vue"
import AccountExclusion from "./views/AccountExclusion.vue"
import Download from "./views/Download.vue"

Vue.use(Router);

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior (to) {
    if (!to.hash) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve({ x: 0, y: 0 })
        }, 1000)
      })
    } else {
      return
    }
  },  
  routes: [
    {
      path: "/home",
      alias: "/",
      name: "home",
      component: Home,
      meta: { logoInicialFalse: true, singletons: ['home'], collections: ['details', 'security'] }
    },
    {
      path: "/como-funciona",
      name: "about",
      component: About,
      meta: { singletons: ['about'], collections: ['about_steps', 'details', 'security'] }
    },
    {
      path: "/quanto-custa",
      name: "pricing",
      component: Pricing,
      meta: { singletons: ['pricing'], collections: ['includes'] }
    },
    {
      path: "/seja-um-parceiro",
      name: "partners",
      component: Partners,
      meta: { singletons: ['partners'] }
    },
    {
      path: "/perguntas-frequentes",
      name: "faq",
      component: FAQ,
      meta: { singletons: ['faq'], collections: ['faq'] }
    },
    {
      path: "/entre-em-contato",
      name: "contacts",
      component: Contacts,
      meta: { singletons: ['contacts'], collections: ['contacts'] }
    },
    {
      path: "/termo-de-uso",
      name: "useTerms",
      component: UseTerms,
      meta: { singletons: ['use_terms'] }
    },
    {
      path: "/politica-de-privacidade",
      name: "privacyPolicy",
      component: PrivacyPolicy,
      meta: { singletons: ['privacy_policy'] }
    },
    {
      path: "/exclusao-de-conta",
      name: "accountExclusion",
      component: AccountExclusion,
      meta: { singletons: ['account_exclusion'] }
    },
    {
      path: "/baixe-o-app",
      name: "download",
      component: Download,
      meta: { singletons: ['home'] }
    },
    {
      path: "/*",
      name: "error404",
      meta: { error: true }
    }
  ]
})
