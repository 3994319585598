<template>
  <div class="error">
    <div>
      <img class="giphy" :src="baseImgUrl + 'travolta.gif'" />
      <h1>Desculpe, mas a página que você procura não existe</h1>
      <p>Será que algum desses outros links podem ser útil para você?</p>
      <ul>
        <li class="title">Sobre a Heppi</li>
        <li><router-link :to="{ name: 'about' }">Como funciona</router-link></li>
        <!-- <li><router-link :to="{ name: 'pricing' }">Quanto custa</router-link></li> -->
        <!--<li><router-link :to="{ name: 'partners' }">Seja parceiro</router-link></li>-->
      </ul>
      <ul>
        <li class="title">Dúvidas</li>
        <li><router-link :to="{ name: 'contacts' }">Entre em contato</router-link></li>
        <li><router-link :to="{ name: 'useTerms' }">Termos de uso</router-link></li>
        <li><router-link :to="{ name: 'privacyPolicy' }">Política de privacidade</router-link></li>
      </ul>
      <img class="logoHeppi" :src="baseImgUrl + 'heppi_negative.png'" :srcset="baseImgUrl + 'heppi_negative@2x.png 2x'" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'error404',
  computed: {
    ...mapGetters(['baseImgUrl'])
  }
}
</script>
