<template>
    <div class="modal" :class="{'active' : updateModal}">
      <div class="modal-background" @click="closeModal()"></div>
      <div class="modal-content">
        <button class="modal-close" @click="closeModal()"></button>
        Uma nova versão do site está disponível.
        <br><br>
        <button class="btn btn-secondary" @click="reloadPage()">
          Atualizar
        </button>
      </div>
    </div>
</template>

<script>

import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'updateModal',
  data () {
    return {

    }
  },
  methods: {
    ...mapMutations(['SET_UPDATE_MODAL']),
    closeModal () {
      this.SET_UPDATE_MODAL(false)
    },
    reloadPage () {
      window.location.reload(true)
    }
  },
  watch: {
    updateModal (value) {
      if (value) {
        document.body.classList.add('modal-opened')
      } else {
        document.body.classList.remove('modal-opened')
      }
    }
  },
  computed: {
    ...mapGetters(['updateModal'])
  }
}
</script>
