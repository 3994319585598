<template>
  <main>
    <div class="container">
      <h1 v-html="principal_title"></h1>
      <p class="subtitle" v-html="principal_subtitle"></p>
      <input class="faq-search" v-model="search" type="text">
      <transition mode="out-in" name="custom-classes-transition" enter-active-class="fade-in" leave-active-class="fade-out">
        <div v-if="filteredQuestions.length == 0" class="faq-question text-center">
          <p>Não encontrou sua dúvida?</p>
          <br>
          <p>
            <router-link :to="{ name: 'contacts' }" class="btn btn-primary">
              Fala com a gente
            </router-link>
          </p>
        </div>
        <template v-else>
          <transition-group tag="div" mode="out-in" name="custom-classes-transition" enter-active-class="fade-in" leave-active-class="fade-out">
            <div class="faq-question" v-for="(question, index) in filteredQuestions" :key="'question-' + index">
              <h2 v-html="question.position + '. ' + question.question"></h2>
              <div v-html="question.answer"></div>
            </div>
          </transition-group>
        </template>
      </transition>
    </div>
  </main>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: "pricing",
  data () {
    return {
      principal_title: 'Perguntas frequentes',
      principal_subtitle: 'Em breve', // 'Respondemos um monte de dúvidas da galera! Dá uma olhada para ver se alguma delas te ajuda. Só não esquece que qualquer coisa estamos por aqui!',
      search: '',
      faq: [
        // {
        //   question: 'Tem pergunta?',
        //   answer: '<p>Rafael Braz precisa dizer.</p>',
        //   position: 1
        // }
      ]
    }
  },
  computed: {
    ...mapGetters(['getSingleton', 'getCollection']),
    filteredQuestions () {
      if (this.search) {
        let upperSearch = this.search.toUpperCase()
        return this.faq.filter(e => {
          let question = e.question.toUpperCase()
          let answer = e.answer.toUpperCase()
          return (question.search(upperSearch) >= 0 || answer.search(upperSearch) >= 0)
        })
      } else {
        return this.faq
      }
    }
  },
  created () {
    if (this.getSingleton('faq')) {
      for (let key in this.getSingleton('faq')) {
        this[key] = this.getSingleton('faq')[key]
      }
    }

    if (this.getCollection('faq')) {
      this.faq = this.getCollection('faq').map((e, i) => {
        return {
          question: e.question,
          answer: e.answer,
          position: i + 1
        }
      })
    }
  }
}
</script>
