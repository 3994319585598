<template>
  <main>
    <div class="container">
      <h1 v-html="principal_title"></h1>
      <p class="subtitle" v-html="principal_subtitle"></p>
      <div>Em breve</div>
    </div>
  </main>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: "partners",
  data () {
    return {
      principal_title: 'Seja um parceiro',
      principal_subtitle: 'Entenda os benefícios de ser um parceiro da Heppi'
    }
  },
  computed: {
    ...mapGetters(['getSingleton'])
  },
  created () {
    if (this.getSingleton('partners')) {
      for (let key in this.getSingleton('partners')) {
        this[key] = this.getSingleton('partners')[key]
      }
    }
  }
}
</script>
