<template>
  <div class="error">
    <div>
      <img class="giphy" :src="baseImgUrl + 'hippo.gif'" />
      <h1>Desculpe, mas estamos indisponíveis no momento</h1>
      <p>Nosso site está em manuteção, mas você pode entrar em contato conosco:</p>
      <ul>
        <li>
          <a href="https://www.instagram.com/souheppi/" target="_blank" rel="nofollow">
            <img :src="baseImgUrl + 'instagram_negative.png'" :srcset="baseImgUrl + 'instagram_negative@2x.png 2x'" alt="Instagram: " />
            @souheppi
          </a>
        </li>
        <li>
          <a :href="whatsappLink" target="_blank" rel="nofollow">
            <img :src="baseImgUrl + 'whatsapp_negative.png'" :srcset="baseImgUrl + 'whatsapp_negative@2x.png 2x'" alt="Whatsapp: " />
            (81) 9 8181-2327
          </a>
        </li>
        <li>
          <a href="mailto:fala@heppi.com.br" target="_blank" rel="nofollow">
            <img :src="baseImgUrl + 'email_negative.png'" :srcset="baseImgUrl + 'email_negative@2x.png 2x'" alt="E-mai: " />
            fala@heppi.com.br
          </a>
        </li>
      </ul>

      <img class="logoHeppi" :src="baseImgUrl + 'heppi_negative.png'" :srcset="baseImgUrl + 'heppi_negative@2x.png 2x'" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'error500',
  computed: {
    ...mapGetters(['whatsappLink', 'baseImgUrl'])
  }
}
</script>
