<template>
  <main>
    <div id="home-wrapper">
      <div class="home-header">
        <div class="container">
          <div class="row row-wrap row-centered">
            <div class="col col-12 col-sm-6 text-center text-sm-left mb-1">
              <img class="home-header-logo" :src="baseImgUrl + 'heppi_negative.png'" :srcset="baseImgUrl + 'heppi_negative@2x.png 2x'" alt="Heppi">
              <h1 class="light" v-html="principal_title"></h1>
              <a v-if="storeLink('android')" :href="storeLink('android')" target="_blank" rel="nofollow" class="donwload-button light">
                <img :src="baseImgUrl + 'google_play.png'" :srcset="baseImgUrl + 'google_play@2x.png 2x'" alt="Baixar na Google Play" />
              </a>
              <a v-if="storeLink('ios')" :href="storeLink('ios')" target="_blank" rel="nofollow" class="donwload-button light">
                <img :src="baseImgUrl + 'app_store.png'" :srcset="baseImgUrl + 'app_store@2x.png 2x'" alt="Baixar na App Store" />
              </a>
            </div>
            <div class="col col-12 col-xs-6 col-sm-6">
              <div class="phone-images">
                <img class="phone-image-right phone-image-top" :src="baseImgUrl + principal_image_back" :srcset="baseImgUrl + image2x(principal_image_back) + ' 2x'" />
                <img class="phone-image-left phone-image-bottom" :src="baseImgUrl + principal_image_front" :srcset="baseImgUrl + image2x(principal_image_front) + ' 2x'" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="home-details" class="home-details">
        <div class="container">
          <h2 class="secondary" v-html="details_title"></h2>
          <p class="subtitle" v-if="details_subtitle" v-html="details_subtitle"></p>
          <div class="points">
            <span v-for="(detail, index) in details" :key="index" :class="{ 'active': homeDetailsStep === index + 1 }"></span>
          </div>
          <div class="row row-wrap row-centered">
            <div class="col col-12 col-xs-6 col-sm-6">
              <div class="phone-images">
                <template v-for="(detail, index) in details">
                  <img :key="'details-back' + index" v-show="homeDetailsStep === index + 1" class="phone-image-left phone-image-top" :src="baseImgUrl + detail.image_back" :srcset="baseImgUrl + image2x(detail.image_back) + ' 2x'" />
                  <img :key="'details-front' + index" v-show="homeDetailsStep === index + 1" class="phone-image-right phone-image-bottom" :src="baseImgUrl + detail.image_front" :srcset="baseImgUrl + image2x(detail.image_front) + ' 2x'" />
                </template>
              </div>
            </div>
            <div class="col col-12 col-sm-6">
              <div v-for="(detail, index) in details" :key="index" class="home-details-item" :class="{ 'active': homeDetailsStep === index + 1 }">
                <img :src="baseImgUrl + detail.icon" :srcset="baseImgUrl + image2x(detail.icon) + ' 2x'" />
                <p class="subtitle secondary" v-html="detail.title"></p>
                <p v-html="detail.subtitle"></p>
              </div>
              <div class="home-details-button">
                <router-link :to="{ name: 'about' }" class="btn btn-secondary">Conheça mais</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="home-security">
        <div class="container">
          <h2 class="light" v-html="security_title"></h2>
          <p class="subtitle" v-html="security_subtitle"></p>
          <div class="row row-wrap">
            <div v-for="item in security" :key="item.icon_negative" class="col col-12 col-xs-4 security-item">
              <img :src="baseImgUrl + item.icon_negative" :srcset="baseImgUrl + image2x(item.icon_negative) + ' 2x'" />
              <p class="subtitle" v-html="item.title"></p>
              <p v-html="item.text"></p>
            </div>
          </div>
        </div>
      </div>
      <div id="download-app" class="home-download">
        <div class="container">
          <h2 v-html="download_title"></h2>
          <p class="subtitle" v-html="download_subtitle"></p>
          <p>
            <a v-if="storeLink('android')" :href="storeLink('android')" target="_blank" rel="nofollow" class="donwload-button">
              <img :src="baseImgUrl + 'google_play_negative.png'" :srcset="baseImgUrl + 'google_play_negative@2x.png 2x'" alt="Baixar na Google Play" />
            </a>
            <a v-if="storeLink('ios')" :href="storeLink('ios')" target="_blank" rel="nofollow" class="donwload-button">
              <img :src="baseImgUrl + 'app_store_negative.png'" :srcset="baseImgUrl + 'app_store_negative@2x.png 2x'" alt="Baixar na App Store" />
            </a>
          </p>
          <hr class="primary limited">
          <p v-html="download_text"></p>
          <p>
            <router-link :to="{ name: 'contacts' }" class="btn btn-primary">
              Fala com a gente
            </router-link>
          </p>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import ScrollMagic from 'scrollmagic'
import { mapGetters } from 'vuex'

export default {
  name: "home",
  props: ['downloadApp'],
  data () {
    return {
      homeDetailsStep: 1,
      duration: 0,
      principal_title: 'Arrecadar para sua formatura nunca foi tão fácil e seguro',
      principal_image_back: 'iphone_finances.png',
      principal_image_front: 'android_fundrising.png',
      details_title: 'Mais liberdade para o participante<br class="hidden shown-xs"> e menos burocracia para o organizador',
      details_subtitle: null,
      details: [
        {
          image_back: 'android_payment.png',
          image_front: 'iphone_my_dash.png',
          icon: 'flexible_payment.png',
          title: 'Fatura flexível',
          subtitle: 'Todas as arrecadações da sua comemoração em uma única fatura'
        },
        {
          image_back: 'android_call.png',
          image_front: 'iphone_notification.png',
          icon: 'inadimplency_control.png',
          title: 'Controle de inadimplência',
          subtitle: 'Nós ajudamos a organização a fazer o controle e a cobrança dos atrasados'
        },
        {
          image_back: 'android_finances_details.png',
          image_front: 'iphone_finances.png',
          icon: 'financial_transparency.png',
          title: 'Transparência financeira',
          subtitle: 'Todo mundo tem acesso aos valores financeiros gerais e pessoais na medida certa'
        }
      ],
      security_title: 'Segurança em<br class="shown hidden-xs"> primeiro lugar',
      security_subtitle: 'Nossa maior preocupação é garantir o sonho da sua turma<br class="hidden shown-xs"> tornando a arrecadação mais segura e transparente para todo mundo:',
      security: [
        {
          icon_negative: 'exclusive_account_negative.png',
          title: 'Conta<br class="hidden shown-xs hidden-md"> exclusiva',
          text: 'Cada comemoração na Heppi tem uma conta exclusiva para a turma onde só ela tem acesso'
        },
        {
          icon_negative: 'financial_responsible_negative.png',
          title: 'Responsável<br class="hidden shown-xs hidden-md"> financeiro',
          text: 'Apenas o responsável financeiro pode solicitar liberação de dinheiro para a conta real da turma'
        },
        {
          icon_negative: 'double_check_negative.png',
          title: 'Dupla<br class="hidden shown-xs hidden-md"> checagem',
          text: 'Quando um liberação de dinheiro é solicitada, nós sorteamos outro organizador para aprovar'
        }
      ],
      download_title: 'Baixe nosso app<br class="shown hidden-xs"> e teste a vontade!',
      download_subtitle: 'É gratuito e não tem limite de dias para teste.<br class="hidden shown-xs"> Sua turma só começa a pagar quando vocês começarem a arrecadar!',
      download_text: 'E se ainda ficar com dúvida, fala com a gente.<br class="hidden shown-xs"> Vamos ter o maior prazer em te ajudar!'
    }
  },
  computed: {
    ...mapGetters(['baseImgUrl', 'storeLink', 'getSingleton', 'getCollection', 'image2x'])
  },
  created () {
    if (this.getSingleton('home')) {
      for (let key in this.getSingleton('home')) {
        this[key] = this.getSingleton('home')[key]
      }
    }

    if (this.getCollection('details')) {
      this.details = this.getCollection('details').map(e => {
        return {
          image_back: e.image_back,
          image_front: e.image_front,
          icon: e.icon,
          title: e.title,
          subtitle: e.subtitle
        }
      })
    }

    if (this.getCollection('security')) {
      this.security = this.getCollection('security').filter(e => e.highlight).map(e => {
        return {
          icon_negative: e.icon_negative,
          title: e.title,
          text: e.text
        }
      })
    }
  },
  mounted () {
    let vue = this
    let offsetTop = document.getElementById('home-details').offsetTop
    vue.duration = document.getElementById('home-details').offsetHeight / 2
    var controller = new ScrollMagic.Controller()

    for (let i = 0, l = vue.details.length; i < l; i++) {
      let scene = new ScrollMagic.Scene({
        duration: vue.duration,
        offset: offsetTop + (vue.duration * i) - 80
      })
      .setPin('#home-wrapper')
      .addTo(controller)

      scene.on('enter', (event) => {
        vue.homeDetailsStep = i + 1
      })
    }

    if (this.$route.hash) {
      let hash = this.$route.hash.split('#')[1]
      let offsetHash = document.getElementById(hash).offsetTop + (this.duration * vue.details.length)
      window.scrollTo(0, offsetHash)
    }
  },
  watch: {
    downloadApp (val) {
      if (val) {
        let hash = this.$route.hash.split('#')[1]
        let offsetHash = document.getElementById(hash).offsetTop + this.duration + this.duration + this.duration
        window.scrollTo(0, offsetHash)
      }
    }
  }
}
</script>
