<template>
  <main>
    <div class="container">
      <h1 v-html="principal_title"></h1>
      <p class="subtitle" v-html="principal_subtitle"></p>
      <div class="row row-wrap row-centered">
        <div v-for="contact in contacts" :key="contact.title" class="col col-12 col-sm-4 contact-item">
          <p class="subtitle secondary my-1">
            <span class="icon-contact">
              <template v-for="(type, index) in contact.type">
                <template v-if="index > 0">+</template>
                <img :key="index" :src="baseImgUrl + contactImage(type)" :srcset="baseImgUrl + image2x(contactImage(type)) + ' 2x'" />
              </template>
            </span>
            <span v-html="contact.title"></span>
          </p>
          <p class="my-1" v-html="contact.text"></p>
          <p class="my-1">
            &nbsp;
            <template v-for="(type, index) in contact.type">
              <a class="btn btn-secondary mb-1" :key="index" :href="contactLink(type, contact.link, contact.title)" target="_blank" rel="nofollow">
                <img :key="index" :src="baseImgUrl + contactImage(type, true)" :srcset="baseImgUrl + image2x(contactImage(type, true)) + ' 2x'" />
                {{ contactLinkContent(type) }}
              </a>
              &nbsp;
            </template>
          </p>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: "pricing",
  data () {
    return {
      principal_title: 'Entra em contato',
      principal_subtitle: 'Tá com dúvida? fala com a gente. Vamos ter o maior prazer em te ajudar!',
      contacts: [
        {
          type: ['Whatsapp'],
          title: '(81) 9 8181-2327',
          text: 'Nos temos um whatsapp. Manda uma mensagem pra nós',
          link: ''
        },
        {
          type: ['E-mail'],
          title: 'fala@heppi.com.br',
          text: 'A gente também responde e-mails rapidinho. =)',
          link: ''
        }
      ]
    }
  },
  computed: {
    ...mapGetters(['whatsappLink', 'baseImgUrl', 'getSingleton', 'getCollection', 'image2x'])
  },
  created () {
    if (this.getSingleton('contacts')) {
      for (let key in this.getSingleton('contacts')) {
        this[key] = this.getSingleton('contacts')[key]
      }
    }

    if (this.getCollection('contacts')) {
      this.contacts = this.getCollection('contacts').map(e => {
        return {
          type: e.type,
          title: e.title,
          text: e.text,
          link: e.link
        }
      })
    }
  },
  methods: {
    numberPhone (phone) {
      return phone.replace(/[)(-. ]/g, '')
    },
    contactImage (type, negative) {
      switch (type) {
        case 'E-mail':
          return (negative) ? 'email_negative.png' : 'email.png'
          break
        case 'Telefone':
          return (negative) ? 'phone_negative.png' : 'phone.png'
          break
        case 'Whatsapp':
          return (negative) ? 'whatsapp_negative.png' : 'whatsapp.png'
          break
        case 'Endereço':
          return (negative) ? 'address_negative.png' : 'address.png'
          break
        default:
          return (negative) ? 'chat_negative.png' : 'chat.png'
      }
    },
    contactLink (type, link, title) {
      switch (type) {
        case 'E-mail':
          return 'mailto:' + title
          break
        case 'Telefone':
          return 'tel:0' + this.numberPhone(title)
          break
        case 'Whatsapp':
          return this.whatsappLink
          break
        case 'Endereço':
          return link
          break
        default:
          return link
      }
    },
    contactLinkContent (type) {
      switch (type) {
        case 'E-mail':
          return 'Envia um e-mail'
          break
        case 'Telefone':
          return 'Liga'
          break
        case 'Whatsapp':
          return 'Fala no Whatsapp'
          break
        case 'Endereço':
          return 'Vê no mapa'
          break
        default:
          return 'Fala com a gente'
      }
    }
  }
}
</script>
