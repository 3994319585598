<template>
  <main>
    <div class="container">
      <h1 v-html="principal_title"></h1>
      <p class="subtitle" v-if="principal_subtitle" v-html="principal_subtitle"></p>
      <div class="text-left" v-html="principal_text"></div>
    </div>
  </main>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: "useTerms",
  data () {
    return {
      principal_title: 'Política de Privacidade',
      principal_subtitle: '',
      principal_text: `<p class="text-right"><i>Última atualização: 05 de junho de 2024</i></p>
                      <br />
                      <p>A presente Política de Privacidade e Segurança de Dados tem por finalidade demonstrar o compromisso da HEPPI DESENVOLVIMENTO DE SISTEMAS LTDA, CNPJ nº 38.661.528/0001-84, com sede na Avenida Governador Agamenon Magalhães, n. 2939, sala 406, n. 2391, sala 904, bairro de Boa Viagem, Recife-PE, CEP 51.020-031, com a privacidade e a proteção dos dados pessoais tratados em suas atividades, em concordância com as leis e regulamentos de privacidade aplicáveis, garantindo transparência e as melhores práticas de proteção de dados pessoais.</p>
                      <h3>1.	SOBRE A HEPPI</h3>
                      <p>1.1.	Somos uma empresa especialista em organização financeira que ajuda formandos e empresas a arrecadarem para formatura com segurança e transparência, de uma forma simples e fácil. O mercado de formaturas hoje sofre com uma grande insegurança quando o assunto é dinheiro. É fácil vermos casos de festas que não acontecem e de prejuízos inexplicáveis. Na grande maioria das vezes, esses problemas são causados por falta de informação e de experiência: organização financeira normalmente é tida como algo chato e extremamente complexo. Quando você une pouca organização com um montante grande de dinheiro, um resultado catastrófico é quase certo. Nosso objetivo é entregar cada vez mais soluções financeiras para esse mercado com intuito de melhorar a saúde financeira do ecossistema e aumentar as oportunidades tanto para formandos quanto para as empresas.</p>
                      <p>1.2.	Nestas atividades, a HEPPI atua como operadora de dados pessoais, em observância às disposições contratuais firmadas com os Controladores dos dados.</p>
                      <h3>2.	FONTES DOS DADOS</h3>
                      <p>2.1.	Os dados pessoais tratados pela HEPPI são capturados por meio de cadastro realizado diretamente pelos titulares no aplicativo ou por meio de bases já existentes, fornecidas pelos usuários.</p>
                      <h3>3.	TIPOS DE DADOS</h3>
                      <p>3.1.	Os dados pessoais tratados são:</p>
                      <p style="margin-left: 30px">3.1.1.	Nome Completo</p>
                      <p style="margin-left: 30px">3.1.2.	Nome Social</p>
                      <p style="margin-left: 30px">3.1.3.	CPF</p>
                      <p style="margin-left: 30px">3.1.4.	Endereço de e-mail</p>
                      <p style="margin-left: 30px">3.1.5.	Telefone</p>
                      <p style="margin-left: 30px">3.1.6.	De forma não obrigatória, podem ser fornecidos o endereço e a data de nascimento.</p>
                      <h3>4.	AGENTES E FINALIDADES DO TRATAMENTO</h3>
                      <p>4.1.	A HEPPI segue o princípio da minimização estabelecido pelo Regulamento Geral de Proteção de Dados da União Européia, que estabelece que "os dados pessoais devem ser adequados, relevantes e limitados ao necessário em relação às finalidades para as quais são tratados" (Art. 5, 1, (c), GDPR), bem como o princípio da necessidade da Lei Geral de Proteção de Dados, definido como a “limitação do tratamento ao mínimo necessário para a realização de suas finalidades, com abrangência dos dados pertinentes, proporcionais e não excessivos em relação às finalidades do tratamento de dados” (Art. 6º, III, Lei Nº 13.709/2018).</p>
                      <p>4.2.	A HEPPI atua como operadora dos dados pessoais, portanto somente poderá tratar os dados para as finalidades dispostas nesta Política de Privacidade e nos contratos firmados diretamente com os Controladores. Essas finalidades são previamente autorizadas (contratadas) pelo controlador e os meios de tratamento empregados também são previamente autorizados (contratados) pelo controlador.</p>
                      <h3>5.	RESPONSABILIDADE DOS AGENTES DE TRATAMENTO</h3>
                      <p>5.1.	A HEPPI realiza o tratamento de dados pessoais como Operador, isto é, agente que realiza tratamento de dados em nome do Controlador, ao qual, por sua vez, competem as decisões referentes ao tratamento, de acordo com o Art. 5º, VI e VII da Lei Geral de Proteção de Dados. </p>
                      <p>5.2.	De acordo com o Capítulo VI, Seção III da LGPD, o controlador ou o operador que causar a outrem dano patrimonial, moral, individual ou coletivo, em razão do tratamento de dados pessoais, é obrigado a repará-lo. Além disso, quando descumprir as obrigações da legislação de proteção de dados ou quando não tiver seguido as instruções lícitas do controlador, o operador responde solidariamente pelos danos causados pelo tratamento, assim como os controladores diretamente envolvidos neste tratamento. Os agentes de tratamento só não serão responsabilizados quando provarem que não realizaram o tratamento de dados pessoais que lhes é atribuído; que não houve violação à legislação de proteção de dados; ou que o dano é decorrente de culpa exclusiva do titular dos dados ou de terceiro.</p>
                      <h3>6.	DADOS PESSOAIS SENSÍVEIS</h3>
                      <p>6.1.	A HEPPI não realiza o tratamento de dados pessoais sensíveis, assim considerados o “dado pessoal sobre origem racial ou étnica, convicção religiosa, opinião política, filiação a sindicato ou a organização de caráter religioso, filosófico ou político, dado referente à saúde ou à vida sexual, dado genético ou biométrico, quando vinculado a uma pessoa natural”, nos termos do art. 5o, II da LGPD.</p>
                      <h3>7.	DADOS DE CRIANÇAS E ADOLESCENTES</h3>
                      <p>7.1.	A Lei Geral de Proteção de Dados (Lei Nº 13.709/2018) estabelece que o tratamento de dados de crianças deve ser realizado apenas com o consentimento específico e em destaque dado por pelo menos um dos pais ou pelo responsável legal (Art. 14, §1º) da criança; e que as instituições envolvidas no tratamento dos dados devem realizar todos os esforços razoáveis para verificar que o consentimento foi dado pelo responsável pela criança (Art. 14, §5º).</p>
                      <p>7.2.	Os produtos oferecidos pela HEPPI não coletam dados pessoais de crianças.</p>
                      <p>7.3.	Os produtos oferecidos pela HEPPI podem coletar dados pessoais de adolescentes, sendo o tratamento destes dados realizado em seu melhor interesse, conforme art. 14 da LGPD.</p>
                      <h3>8.	BASES LEGAIS</h3>
                      <p>8.1.	A depender do produto oferecido, os dados pessoais tratados pela HEPPI podem ter como base legal o consentimento pelo titular (art. 7o, I), o cumprimento de obrigação legal ou regulatória pelo controlador (art. 7o, II), e para atender os interesses legítimos do controlador ou de terceiro (art. 7o, IX).</p>
                      <p>8.2.	O Consentimento do titular, quando apontado como base legal do tratamento de dados, será obtido mediante declaração expressa obtida eletronicamente.</p>
                      <h3>9.	FORMA E DURAÇÃO DO TRATAMENTO</h3>
                      <p>9.1.	Os dados pessoais tratados pela HEPPI, na qualidade de Operadora, são armazenados apenas pelo prazo de duração da relação contratual existente com o Controlador dos Dados. Após este prazo, os dados pessoais serão eliminados ou anonimizados, caso em que poderão ser utilizados para aprimoramento dos produtos existentes e desenvolvimento de novos produtos, pesquisas científicas e fins estatísticos, sem qualquer identificação dos Titulares.</p>
                      <h3>10.	DECISÕES AUTOMATIZADAS</h3>
                      <p>10.1.	No âmbito dos produtos desenvolvidos pela HEPPI, não são tomadas decisões automatizadas.</p>
                      <h3>11.	MEDIDAS DE SEGURANÇA</h3>
                      <p>11.1.	Todos os dados, não só os pessoais, são tratados pela HEPPI com todo o zelo e segurança necessários, com implementação completa do nosso sistema dentro da Amazon Web Services (AWS), trafegando-os com todas as políticas de acesso bem configuradas e restritas, onde apenas os colaboradores que assim precisam acessá-los o façam.</p>
                      <p>11.2.	Nossas políticas de controle de acesso aos dados gerenciam o limite de uso de cada grupo de usuários que atuam na HEPPI. Para cada camada de acesso, possuímos políticas de uso das ferramentas associadas ao fluxo do sistema.</p>
                      <p>11.3.	O fluxo de trabalho NÃO depende do grupo de usuários, visto que existem políticas de acesso dos serviços dentro da AWS que eliminam a necessidade de vincular um usuário ou grupo de usuários ao fluxo do projeto. Dessa forma, o fluxo fica individualizado e restrito no dia a dia da aplicação.</p>
                      <p>11.4.	O processo de guarda das informações (backup), é feito em ambiente separado da produção (isolamento e disaster recovery), utilizando-se técnicas de criptografia e automação (segurança) e acesso ainda mais restrito, ficando apenas a gestão com a visão necessária e via credencial específica para tal. Utilizamos a ferramenta da AWS chamada Simple Storage Service (S3), que persiste as informações de tempo real que são necessárias para bom uso do sistema e mantém em armazenamento a frio (S3 Glacier) todas as informações não mais necessárias para o ambiente de produção, seguindo políticas de ciclo de vida implementadas pela HEPPI.</p>
                      <p>11.5.	A política de senhas implementada na HEPPI é bem definida e há um rígido controle para uso das mesmas e dos recursos (dados) que cada colaborador pode utilizar de acordo com sua função. Além disso, é de caráter obrigatório que todos os usuários acessem a AWS utilizando Autenticação Multifatores (MFA), eliminando a liberdade de acesso utilizando apenas login e senha. Nesse caso, o usuário é obrigado a usar serviços de autenticação externos como Google Authenticator, Microsoft Authenticator e similares.</p>
                      <p>11.6.	Todos os produtos tecnológicos utilizados para tratamento de dados já estão configurados segundo melhores práticas de segurança/criptografia/processo, de forma a melhor atender aos requisitos de segurança e disponibilidade. A HEPPI utiliza como referência as normas da ISO 27001 de padrão e a referência Internacional para a gestão da Segurança da informação.</p>
                      <h3>12.	COMPARTILHAMENTO</h3>
                      <p>12.1.	Os dados tratados pela HEPPI são compartilhados exclusivamente com as comissões de formatura responsáveis pela gestão dos eventos do usuário. A HEPPI não possui responsabilidade sobre o uso dos dados dos terceiros quando ocorre a autorização explícita das Controladoras para o envio das informações aos mesmos.</p>
                      <p>13.	DIREITOS DO TITULAR</p>
                      <p>13.1.	A HEPPI realiza o tratamento de dados pessoais em nome de um controlador, caracterizando-se, portanto, como operador, de acordo com o Art. 5º, VII da Lei Geral de Proteção de Dados. Por esse motivo, garantimos o exercício dos direitos do titular de dados previstos na LGPD, no entanto, esses direitos devem ser solicitados ao controlador de dados pessoais, que, por sua vez, deve nos encaminhar as requisições. Toda requisição advinda do controlador e atendida por nós, será notificada de volta ao controlador, que, por sua vez, deverá informar ao titular. Abaixo, segue a lista simplificada de direitos previstos na Lei 13.709/2018:</p>
                      <p style="margin-left: 30px">13.1.1.	Direito de acesso à informação: As informações exigidas pelas leis de proteção de dados constam na Política de Privacidade. Outras informações podem ser solicitadas ao controlador ou diretamente a nós, quando aplicável, através do email juridico@heppi.com.br</p>
                      <p style="margin-left: 30px">13.1.2.	Direito à confirmação do tratamento de dados pessoais: A confirmação do tratamento deverá ser solicitada diretamente ao controlador, que nos encaminhará a sua requisição.</p>
                      <p style="margin-left: 30px">13.1.3.	Direito de acesso aos dados: O acesso aos dados deverá ser solicitado diretamente ao controlador, que nos encaminhará a requisição. Ao exercer o direito de acesso aos dados, informaremos ao controlador os tipos e categorias de dados que temos sobre você, bem como as finalidades. A cópia eletrônica integral dos dados, no entanto, se aplica apenas quando o tratamento tiver origem no consentimento do titular ou em contrato, e não no legítimo interesse, nos termos do art. 19, § 3º da Lei Nº 13.709/2018. </p>
                      <p style="margin-left: 30px">13.1.4.	Direito à correção de dados incompletos, inexatos ou desatualizados: A HEPPI garante a exatidão, clareza, relevância e atualização dos dados pessoais coletados através da nossa tecnologia. Entretanto, caso considere que os dados pessoais tratados pela HEPPI estão incompletos, inexatos ou desatualizados, poderá solicitar a retificação diretamente ao controlador, que nos encaminhará a requisição.</p>
                      <p style="margin-left: 30px">13.1.5.	Direito a anonimização, bloqueio, eliminação ou restrição de dados desnecessários, excessivos ou tratados em desconformidade com a lei: A HEPPI trata apenas dados estritamente necessários para a prestação de seus serviços, observando todas as determinações da Lei Geral de Proteção de Dados e outras normas aplicáveis. No entanto, caso queira solicitar a anonimização, bloqueio, eliminação ou restrição de dados, entre em contato com o controlador que avaliará a sua requisição e nos encaminhará a sua solicitação.</p>
                      <p style="margin-left: 30px">13.1.6.	Direito à portabilidade de dados: A HEPPI desenvolveu sua tecnologia proprietária de forma inovadora e não compatível com outras tecnologias similares, por isso, nem sempre é possível exercer a portabilidade de dados da HEPPI para outro fornecedor de serviço semelhante sem prejuízo dos segredos comercial e industrial da empresa.</p>
                      <p style="margin-left: 30px">13.1.7.	Direito à informação das entidades públicas e privadas com as quais a HEPPI realizou uso compartilhado de dados: Informamos, na seção “Compartilhamento” desta Política de Privacidade, os destinatários do compartilhamento dos dados. Caso queira solicitar alguma informação adicional, deverá entrar em contato através do e-mail juridico@heppi.com.br.</p>
                      <p style="margin-left: 30px">13.1.8.	Direito à revisão de decisões automatizadas: A HEPPI não trabalha com decisões automatizadas em seu produto</p>
                      <p style="margin-left: 30px">13.1.9.	Direito de não se sujeitar a tratamento exclusivamente automatizado: O tratamento automatizado é realizado para fins de cumprimento de obrigação legal e regulatória. Ainda assim, caso considere exercer esse direito, deverá solicitar diretamente ao controlador dos dados, que nos encaminhará a requisição.</p>
                      <p style="margin-left: 30px">13.1.10.	Direito a cópia integral dos dados; informação sobre negativa ao consentimento e consequências; revogação do consentimento (“opt-out”); direito à eliminação de dados tratados com o consentimento do usuário: Estes direitos se aplicam apenas ao tratamento de dados pessoais baseado no consentimento.</p>
                      <h3>14.	CONTATO</h3>
                      <p>14.1.	Se tiver alguma dúvida, comentário ou sugestão, por favor, entre em contato com o nosso Encarregado do Tratamento de Dados Pessoais (“Data Protection Officer”), Sra Laís Moutinho, a qual pode ser contatada através do e-mail juridico@heppi.com.br.</p>
                      <br /><br />
                      <p class="text-center"><b><b>HEPPI</b> DESENVOLVIMENTO DE SISTEMAS LTDA</b><br />CNPJ/MF nº 38.661.528/0001-84</p>`
    }
  },
  computed: {
    ...mapGetters(['getSingleton'])
  },
  created () {
    if (this.getSingleton('use_terms')) {
      for (let key in this.getSingleton('use_terms')) {
        this[key] = this.getSingleton('use_terms')[key]
      }
    }
  }
}
</script>
