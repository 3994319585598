import Vue from "vue"
import Vuex from "vuex"
import axios from "axios"
import router from "./router"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    collections: {},
    singletons: {},
    errorPage: null,
    updateModal: false
  },
  getters: {
    getCollection: (state) => (id) => {
      return state.collections[id] || null
    },
    getSingleton: (state) => (id) => {
      return state.singletons[id] || null
    },
    errorPage (state) {
      return state.errorPage
    },
    whatsappLink () {
      if (navigator.userAgent.match(/Android/i) ||
        navigator.userAgent.match(/webOS/i) ||
        navigator.userAgent.match(/iPhone/i) ||
        navigator.userAgent.match(/iPad/i) ||
        navigator.userAgent.match(/iPod/i) ||
        navigator.userAgent.match(/BlackBerry/i) ||
        navigator.userAgent.match(/Windows Phone/i)) {
        return 'https://wa.me/5581981812327'
      } else {
        return 'https://wa.me/5581981812327'
      }
    },
    baseImgUrl () {
      return (process.env.VUE_APP_IMG_URL) ? process.env.VUE_APP_IMG_URL : './img/'
    },
    storeLink: () => (type) => {
      let link = false
      if (!type) {
        if (navigator.userAgent.match(/Android/i)) {
          type = 'android'
        } else if (navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPod/i)) {
          type = 'ios'
        }
      }
      if (type === 'android' && process.env.VUE_APP_GOOGLE_PLAY) link = process.env.VUE_APP_GOOGLE_PLAY
      if (type === 'ios' && process.env.VUE_APP_APP_STORE) link = process.env.VUE_APP_APP_STORE
      return link
    },
    image2x: () => (image) => {
      let imgArray = image.split('.')
      let imgExtension = imgArray[imgArray.length - 1]
      imgArray.pop()
      let imgName = imgArray.join('')
      
      return imgName + '@2x.' + imgExtension
    },
    updateModal (state) {
      return state.updateModal
    }
  },
  mutations: {
    'POPULATE_SINGLETONS' (state, singletonData) {
      if (singletonData.name && singletonData.content) {
        state.singletons[singletonData.name] = singletonData.content
      }
    },
    'POPULATE_COLLECTIONS' (state, collectionData) {
      if (collectionData.name && collectionData.content) {
        if (collectionData.content[0].order) {
          state.collections[collectionData.name] = collectionData.content.sort((a, b) => {
            return a.order - b.order
          })
        } else {
          state.collections[collectionData.name] = collectionData.content
        }
      }
    },
    'SET_ERROR_PAGE' (state, errorPage) {
      if (errorPage) state.errorPage = errorPage
      else state.errorPage = null
    },
    'SET_UPDATE_MODAL' (state, isVisible) {
      state.updateModal = isVisible
    }
  },
  actions: {
    populateAllData ({ state, commit }, siteData) {
      // If has routes
      if (router.options && router.options.routes) {
        // List exist data
        let singletons = Object.keys(state.singletons)
        let collections = Object.keys(state.collections)
        
        // List needed data
        for (let i = 0, l = router.options.routes.length; i < l; i++) {
          if (router.options.routes[i].meta && router.options.routes[i].meta.singletons) {
            singletons = [...singletons, ...router.options.routes[i].meta.singletons.filter(e => !singletons.includes(e))]
          }
          if (router.options.routes[i].meta && router.options.routes[i].meta.collections) {
            collections = [...collections, ...router.options.routes[i].meta.collections.filter(e => !collections.includes(e))]
          }
        }

        // Get needed data
        for (let i = 0, l = singletons.length; i < l; i++) {
          if (!state.singletons[singletons[i]]) {
            axios.get('singletons/get/' + singletons[i])
              .then((response) => {
                commit('POPULATE_SINGLETONS', { name: singletons[i], content: response.data })
              })
          }
        }

        for (let i = 0, l = collections.length; i < l; i++) {
          if (!state.collections[collections[i]]) {
            axios.get('collections/get/' + collections[i])
              .then((response) => {
                commit('POPULATE_COLLECTIONS', { name: collections[i], content: response.data.entries })
              })
          }
        }
      }
    },
    populatePageData ({ commit, dispatch }, siteData) {
      return new Promise ((resolve, reject) => {
        let promises = []

        if (siteData.singletons) {
          for (let i = 0, l = siteData.singletons.length; i < l; i++) {
            promises.push(new Promise ((resolveSingleton, rejectSingleton) => {
              axios.get('singletons/get/' + siteData.singletons[i])
                .then((response) => {
                  commit('POPULATE_SINGLETONS', { name: siteData.singletons[i], content: response.data })
                  resolveSingleton()
                })
                .catch(error => {
                  rejectSingleton(error)
                })
            }))
          }
        }

        if (siteData.collections) {
          for (let i = 0, l = siteData.collections.length; i < l; i++) {
            promises.push(new Promise ((resolveSingleton, rejectSingleton) => {
              axios.get('collections/get/' + siteData.collections[i])
                .then((response) => {
                  commit('POPULATE_COLLECTIONS', { name: siteData.collections[i], content: response.data.entries })
                  resolveSingleton()
                })
                .catch(error => {
                  rejectSingleton(error)
                })
            }))
          }
        }

        Promise.all(promises)
          .then(() => {
            resolve()
            dispatch('populateAllData')
          })
          .catch((error) => {
            reject(error)
          })
      })
    }
  }
})
