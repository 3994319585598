<template>
  <main>
    <div class="container">
      <h1 v-html="principal_title"></h1>
      <p class="subtitle" v-html="principal_subtitle"></p>
      <div class="row row-wrap row-centered">
        <div v-for="step in about_steps" :key="step.icon" class="col col-12 col-sm-4 about-item">
          <img :src="baseImgUrl + step.icon" :srcset="baseImgUrl + image2x(step.icon) + ' 2x'" />
          <p class="subtitle primary" v-html="step.title"></p>
          <p v-html="step.text"></p>
        </div>
      </div>
      <a href="#download-app" class="btn btn-secondary mt-2">Vem arrecadar com a Heppi</a>
    </div>
    <div class="container">
      <br>
      <hr class="secondary">
      <div v-for="detail in details" :key="detail.icon" class="row row-wrap row-centered details-item">
        <div class="col col-12 col-sm-6 details-item-images">
          <div class="phone-images">
            <img class="phone-image-left phone-image-top" :src="baseImgUrl + detail.image_back" :srcset="baseImgUrl + image2x(detail.image_back) + ' 2x'" />
            <img class="phone-image-right phone-image-bottom" :src="baseImgUrl + detail.image_front" :srcset="baseImgUrl + image2x(detail.image_front) + ' 2x'" />
          </div>
        </div>
        <div class="col col-12 col-sm-6 details-item-text">
          <h2 class="secondary">
            <img :src="baseImgUrl + detail.icon" :srcset="baseImgUrl + image2x(detail.icon) + ' 2x'">
            <span v-html="detail.title"></span>
          </h2>
          <p class="subtitle" v-html="detail.subtitle"></p>
          <div v-html="detail.text"></div>
        </div>
      </div>
    </div>
    <div class="container">
      <br>
      <hr class="secondary">
      <br>
      <h2 v-html="security_title"></h2>
      <p class="subtitle" v-html="security_subtitle"></p>
      <div class="row row-wrap row-centered">
        <div v-for="item in security" :key="item.icon" class="col col-12 col-xs-4 security-item">
          <img :src="baseImgUrl + item.icon" :srcset="baseImgUrl + image2x(item.icon) + ' 2x'" />
          <p class="subtitle secondary" v-html="item.title"></p>
          <p v-html="item.text"></p>
        </div>
      </div>
    </div>
    <div id="download-app" class="container">
      <br>
      <hr class="secondary">
      <br>
      <h2 v-html="download_title"></h2>
      <p class="subtitle" v-html="download_subtitle"></p>
      <p>
        <a v-if="storeLink('android')" :href="storeLink('android')" target="_blank" rel="nofollow" class="donwload-button">
          <img :src="baseImgUrl + 'google_play_negative.png'" :srcset="baseImgUrl + 'google_play_negative@2x.png 2x'" alt="Baixar na Google Play" />
        </a>
        <a v-if="storeLink('ios')" :href="storeLink('ios')" target="_blank" rel="nofollow" class="donwload-button">
          <img :src="baseImgUrl + 'app_store_negative.png'" :srcset="baseImgUrl + 'app_store_negative@2x.png 2x'" alt="Baixar na App Store" />
        </a>
      </p>
      <hr class="primary limited">
      <p v-html="download_text"></p>
      <p>
        <router-link :to="{ name: 'contacts' }" class="btn btn-primary">
          Fala com a gente
        </router-link>
      </p>
    </div>
  </main>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: "about",
  data () {
    return {
      principal_title: 'Arrecade de forma inteligente',
      principal_subtitle: 'Você cria uma comemoração e em pouco tempo sua turma já está arrecadando:',
      about_steps: [
        {
          icon: 'create_celebration.png',
          title: 'Crie uma comemoração',
          text: 'Com algumas informações, a gente cria uma conta Heppi exclusiva para a comemoração da sua turma'
        },
        {
          icon: 'add_people.png',
          title: 'Convide a galera',
          text: 'Você chama todo mundo pra participar e quem entrar na comemoração já pode começar a arrecadar'
        },
        {
          icon: 'create_fundrising.png',
          title: 'Crie arrecadações extras',
          text: 'Além da arrecadação principal vocês podem ter outras para extras, como viagens…'
        }
      ],
      details: [
        {
          image_back: 'android_payment.png',
          image_front: 'iphone_my_dash.png',
          icon: 'flexible_payment.png',
          title: 'Fatura flexível',
          subtitle: 'Todas as arrecadações da sua comemoração em uma única fatura',
          text: '<ul>\n<li>Cada participante escolhe quando quer pagar: A fatura &eacute; gerada no dia 5 e pode ser paga at&eacute; o último dia do mês</li>\n<li>Vem detalhado na fatura tudo que o participante aderiu e em que parcela est&aacute;</li>\n<li>A organiza&ccedil;&atilde;o configura quanto de juros e multa ser&aacute; aplicado em caso de atraso</li>\n</ul>'
        },
        {
          image_back: 'android_call.png',
          image_front: 'iphone_notification.png',
          icon: 'inadimplency_control.png',
          title: 'Controle de inadimplência',
          subtitle: 'Ajudamos a organização a fazer o controle e a cobrança dos atrasados através de um método heppi',
          text: '<ul>\n<li>Mapear a realidade da turma</li>\n<li>Controle do fluxo de caixa</li>\n<li>Acompanhamento Periódico</li>\n<li>Atendimento individual e humanizado em todo processo da renegociação</li>\n</ul>'
        },
        {
          image_back: 'android_finances_details.png',
          image_front: 'iphone_finances.png',
          icon: 'financial_transparency.png',
          title: 'Transparência financeira',
          subtitle: 'Todo mundo tem acesso aos valores financeiros gerais e pessoais na medida certa',
          text: '<ul>\n<li>Todo participante tem acesso aos valores totais e um extrato de presta&ccedil;&atilde;o de contas</li>\n<li>Somente organizadores tem acesso a lista de quem pagou e de quem est&aacute; atrasado</li>\n<li>E apenas o respons&aacute;vel financeiro solicita libera&ccedil;&atilde;o de dinheiro para a conta real da turma</li>\n</ul>'
        }
      ],
      security_title: 'Mas tudo isso é seguro?',
      security_subtitle: 'Nossa maior preocupação é garantir o sonho da sua turma<br class="hidden shown-xs"> tornando a arrecadação mais segura e transparente para todo mundo:',
      security: [
        {
          icon: 'exclusive_account.png',
          title: 'Conta<br class="hidden shown-xs hidden-md"> exclusiva',
          text: 'Cada comemoração na Heppi tem uma conta exclusiva para a turma onde só ela tem acesso'
        },
        {
          icon: 'financial_responsible.png',
          title: 'Responsável<br class="hidden shown-xs hidden-md"> financeiro',
          text: 'Apenas o responsável financeiro pode solicitar liberação de dinheiro para a conta real da turma'
        },
        {
          icon: 'double_check.png',
          title: 'Dupla<br class="hidden shown-xs hidden-md"> checagem',
          text: 'Quando um liberação de dinheiro é solicitada, nós sorteamos outro organizador para aprovar'
        },
        {
          icon: 'total_control.png',
          title: 'Controle<br class="hidden shown-xs hidden-md"> total',
          text: 'O dinheiro arrecadado pode ser usado quando quiser, sem limite de valor nem de quantidade'
        },
        {
          icon: 'heppi_monitoring.png',
          title: 'Monitoramento<br class="hidden shown-xs hidden-md"> Heppi',
          text: 'Ficamos de olho nas transações e contactamos a turma sempre que encontramos algo estranho'
        }
      ],
      download_title: 'Baixe nosso app<br class="shown hidden-xs"> e teste a vontade!',
      download_subtitle: 'É gratuito e não tem limite de dias para teste.<br class="hidden shown-xs"> Sua turma só começa a pagar quando vocês começarem a arrecadar!',
      download_text: 'E se ainda ficar com dúvida, fala com a gente.<br class="hidden shown-xs"> Vamos ter o maior prazer em te ajudar!'
    }
  },
  computed: {
    ...mapGetters(['baseImgUrl', 'storeLink', 'getSingleton', 'getCollection', 'image2x'])
  },
  created () {
    if (this.getSingleton('about')) {
      for (let key in this.getSingleton('about')) {
        this[key] = this.getSingleton('about')[key]
      }
    }

    if (this.getCollection('about_steps')) {
      this.about_steps = this.getCollection('about_steps').map(e => {
        return {
          icon: e.icon,
          title: e.title,
          text: e.text
        }
      })
    }

    if (this.getCollection('details')) {
      this.details = this.getCollection('details').map(e => {
        return {
          image_back: e.image_back,
          image_front: e.image_front,
          icon: e.icon,
          title: e.title,
          subtitle: e.subtitle,
          text: e.text
        }
      })
    }

    if (this.getCollection('security')) {
      this.security = this.getCollection('security').map(e => {
        return {
          icon: e.icon,
          title: e.title,
          text: e.text
        }
      })
    }
  }
}
</script>
