import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './registerServiceWorker'
import './assets/scss/heppi.scss'
import axios from "axios"

Vue.config.productionTip = false

// axios.defaults.baseURL = process.env.VUE_APP_BASE_URL_API
// axios.defaults.headers.common['Cockpit-Token'] = process.env.VUE_APP_TOKEN_API

// router.beforeEach((to, from, next) => {
//   if (to.meta && to.meta.singletons) {
//     let singletons = to.meta.singletons || []
//     let collections = to.meta.collections || []
//     if (!store.getters.getSingleton(singletons[0])) {
//       store.dispatch('populatePageData', { singletons, collections })
//         .then(() => {
//           document.title = store.getters.getSingleton(singletons[0]).title || 'Heppi'
//           document.querySelector('meta[name="description"]').content = store.getters.getSingleton(singletons[0]).description || 'Arrecadar para sua formatura nunca foi tão fácil e seguro'
//           next()
//         })
//         .catch(() => {
//           // store.commit('SET_ERROR_PAGE', 'Error500')
//           document.title = 'Heppi'
//           document.querySelector('meta[name="description"]').content = 'Arrecadar para sua formatura nunca foi tão fácil e seguro'
//           next()
//         })
//     } else {
//       document.title = store.getters.getSingleton(singletons[0]).title || 'Heppi'
//       document.querySelector('meta[name="description"]').content = store.getters.getSingleton(singletons[0]).description || 'Arrecadar para sua formatura nunca foi tão fácil e seguro'
//       next()
//     }
//   } else {
//     next()
//   }
// })

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
