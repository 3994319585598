<template>
  <main>
    <div class="container">
      <h1 v-html="principal_title"></h1>
      <p class="subtitle" v-html="principal_subtitle"></p>
      <img class="cost-image" :src="baseImgUrl + principal_image" :srcset="baseImgUrl + image2x(principal_image) + ' 2x'" />
      <div class="row row-centered">
        <div class="col col-md-8">
          <p v-html="principal_text"></p>
        </div>
      </div>
      <br><br>
    </div>
    <div class="container">
      <h2 v-html="includes_title"></h2>
      <p class="subtitle" v-html="includes_subtitle"></p>
      <div class="row row-wrap">
         <div class="row row-wrap row-centered">
          <div v-for="item in includes" :key="item.icon" class="col col-12 col-xs-4 security-item">
            <img :src="baseImgUrl + item.icon" :srcset="baseImgUrl + image2x(item.icon) + ' 2x'" />
            <p class="subtitle secondary" v-html="item.title"></p>
            <p v-html="item.text"></p>
          </div>
        </div>
      </div>
    </div>
    <div id="download-app" class="container">
      <br>
      <hr class="secondary">
      <br>
      <h2 v-html="download_title"></h2>
      <p class="subtitle" v-html="download_subtitle"></p>
      <p>
        <a v-if="storeLink('android')" :href="storeLink('android')" target="_blank" rel="nofollow" class="donwload-button">
          <img :src="baseImgUrl + 'google_play_negative.png'" :srcset="baseImgUrl + 'google_play_negative@2x.png 2x'" alt="Baixar na Google Play" />
        </a>
        <a v-if="storeLink('ios')" :href="storeLink('ios')" target="_blank" rel="nofollow" class="donwload-button">
          <img :src="baseImgUrl + 'app_store_negative.png'" :srcset="baseImgUrl + 'app_store_negative@2x.png 2x'" alt="Baixar na App Store" />
        </a>
      </p>
      <hr class="primary limited">
      <p v-html="download_text"></p>
      <p>
        <router-link :to="{ name: 'contacts' }" class="btn btn-primary">
          Fala com a gente
        </router-link>
      </p>
    </div>
  </main>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: "pricing",
  data () {
    return {
      principal_title: 'Quanto custa',
      principal_subtitle: 'Aqui não tem mensalidade, você só paga quando usa.<br>E só recebemos quando o participante paga.',
      principal_image: 'cost.png',
      principal_text: 'Cobramos um adicional em cada fatura. Isso significa que você só paga quando realmente precisa dos nossos serviços. E ao invés do valor ser debitado da organização é vem como um adicional, ou seja o participante sabe que está pagando pelo serviço da Heppi e a organização não é penalizada por isso.',
      includes_title: 'E o que está incluso?',
      includes_subtitle: 'Além do acesso a todas as funcionalidades do app<br class="hidden shown-sm"> que é gratuito, também está incluso:',
      includes: [
        {
          icon: 'slip_bank.png',
          title: 'Custos do boleto',
          text: 'Não é cobrado nada a mais pela emissão do boleto. já está incluso na nossa taxa.'
        },
        {
          icon: 'support.png',
          title: 'Suporte especializado',
          text: 'Estamos disponíveis tanto para os organizadores quanto para cada um dos participantes.'
        },
        {
          icon: 'inadimplency_control_cost.png',
          title: 'Controle da Inadimplência',
          text: 'Temos uma metodologia ativa especializada para diminuir a inadimplência da sua turma.'
        }
      ],
      download_title: 'Baixe nosso app e já comece a testar!',
      download_subtitle: 'É gratuito e não tem limite de dias para teste!',
      download_text: 'E se ainda ficar com dúvida, fala com a gente.<br class="hidden shown-xs"> Vamos ter o maior prazer em te ajudar!'
    }
  },
  computed: {
    ...mapGetters(['baseImgUrl', 'storeLink', 'image2x', 'getSingleton', 'getCollection'])
  },
  created () {
    if (this.getSingleton('pricing')) {
      for (let key in this.getSingleton('pricing')) {
        this[key] = this.getSingleton('pricing')[key]
      }
    }

    if (this.getCollection('includes')) {
      this.includes = this.getCollection('includes').map((e, i) => {
        return {
          icon: e.icon,
          title: e.title,
          text: e.text,
          position: i + 1
        }
      })
    }
  }
}
</script>
