<template>
  <main>
    <div class="container">
      <h1 v-html="download_title"></h1>
      <p class="subtitle" v-html="download_subtitle"></p>
      <p>
        <a v-if="storeLink('android')" :href="storeLink('android')" target="_blank" rel="nofollow" class="donwload-button">
          <img :src="baseImgUrl + 'google_play_negative.png'" :srcset="baseImgUrl + 'google_play_negative@2x.png 2x'" alt="Baixar na Google Play" />
        </a>
        <a v-if="storeLink('ios')" :href="storeLink('ios')" target="_blank" rel="nofollow" class="donwload-button">
          <img :src="baseImgUrl + 'app_store_negative.png'" :srcset="baseImgUrl + 'app_store_negative@2x.png 2x'" alt="Baixar na App Store" />
        </a>
      </p>
      <hr class="primary limited">
      <p v-html="download_text"></p>
      <p>
        <router-link :to="{ name: 'contacts' }" class="btn btn-primary">
          Fala com a gente
        </router-link>
      </p>
    </div>
  </main>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: "about",
  data () {
    return {
      download_title: 'Baixe nosso app<br class="shown hidden-xs"> e teste a vontade!',
      download_subtitle: 'É gratuito e não tem limite de dias para teste.<br class="hidden shown-xs"> Sua turma só começa a pagar quando vocês começarem a arrecadar!',
      download_text: 'E se ainda ficar com dúvida, fala com a gente.<br class="hidden shown-xs"> Vamos ter o maior prazer em te ajudar!'
    }
  },
  computed: {
    ...mapGetters(['baseImgUrl', 'storeLink', 'getSingleton'])
  },
  created () {
    if (this.getSingleton('home')) {
      for (let key in this.getSingleton('home')) {
        if (key.search('home')) {
          this[key] = this.getSingleton('home')[key]
        }
      }
    }

    if (this.storeLink()) {
      window.location = this.storeLink()
    }
  }
}
</script>
