<template>
  <footer>
    <div class="container">
      <div class="row row-wrap row-nowrap-md">
        <div class="col col-auto text-center text-xs-left">
          <router-link class="footer-home-link" :to="{ name: 'home' }">
            <img :src="baseImgUrl + 'heppi_negative.png'" :srcset="baseImgUrl + 'heppi_negative@2x.png 2x'" />
          </router-link>
        </div>
        <div class="col col-auto text-center text-xs-right">
          <a v-if="storeLink('android')" :href="storeLink('android')" target="_blank" rel="nofollow" class="donwload-button">
            <img :src="baseImgUrl + 'google_play_negative.png'" :srcset="baseImgUrl + 'google_play_negative@2x.png 2x'" alt="Baixar na Google Play" />
          </a>
          <a v-if="storeLink('ios')" :href="storeLink('ios')" target="_blank" rel="nofollow" class="donwload-button">
            <img :src="baseImgUrl + 'app_store_negative.png'" :srcset="baseImgUrl + 'app_store_negative@2x.png 2x'" alt="Baixar na App Store" />
          </a>
        </div>
      </div>
      <div class="row row-wrap row-nowrap-md">
        <nav class="col col-12 col-md-6">
          <ul class="footer-nav text-center text-xs-left">
            <li class="title">Sobre a Heppi</li>
            <li><router-link :to="{ name: 'about' }">Como funciona</router-link></li>
            <!-- <li><router-link :to="{ name: 'pricing' }">Quanto custa</router-link></li> -->
            <!--<li><router-link :to="{ name: 'partners' }">Seja parceiro</router-link></li>-->
          </ul>
          <ul class="footer-nav text-center text-xs-left">
            <li class="title">Dúvidas</li>
            <!-- <li><router-link :to="{ name: 'faq' }">Perguntas frequentes</router-link></li> -->
            <li><router-link :to="{ name: 'contacts' }">Entre em contato</router-link></li>
          </ul>
        </nav>
        <div class="col col-12 col-md-6">
          <div class="footer-instagram">
            <span class="title">Seja um heppie também:</span>
            <a href="https://www.instagram.com/souheppi/" class="btn btn-negative-secondary">
              <img :src="baseImgUrl + 'instagram.png'" :srcset="baseImgUrl + 'instagram@2x.png 2x'" alt="Instagram:" /> @souheppi
            </a>
          </div>
        </div>
      </div>
      <br><br>
      <div class="row row-centered">
        <div class="col col-auto">
          <div class="footer-copyright">
            HEPPI DESENVOLVIMENTO DE SISTEMAS LTDA - CNPJ: 38.661.528/0001-84
            Copyright 2019®
            <br />
            <router-link :to="{ name: 'useTerms' }">Termos de uso</router-link> <router-link :to="{ name: 'privacyPolicy' }">Política de privacidade</router-link>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: "appfooter",
  computed: {
    ...mapGetters(['baseImgUrl', 'storeLink'])
  }
}
</script>
