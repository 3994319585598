<template>
  <main>
    <div class="container">
      <h1 v-html="principal_title"></h1>
      <p class="subtitle" v-if="principal_subtitle" v-html="principal_subtitle"></p>
      <div class="text-left" v-html="principal_text"></div>
      <div class="row row-wrap row-centered">
        <div v-for="contact in contacts" :key="contact.title" class="col col-12 col-sm-4 contact-item">
          <p class="subtitle secondary my-1">
            <span class="icon-contact">
              <template v-for="(type, index) in contact.type">
                <template v-if="index > 0">+</template>
                <img :key="index" :src="baseImgUrl + contactImage(type)" :srcset="baseImgUrl + image2x(contactImage(type)) + ' 2x'" />
              </template>
            </span>
            <span v-html="contact.title"></span>
          </p>
          <p class="my-1" v-html="contact.text"></p>
          <p class="my-1">
            &nbsp;
            <template v-for="(type, index) in contact.type">
              <a class="btn btn-secondary mb-1" :key="index" :href="contactLink(type, contact.link, contact.title)" target="_blank" rel="nofollow">
                <img :key="index" :src="baseImgUrl + contactImage(type, true)" :srcset="baseImgUrl + image2x(contactImage(type, true)) + ' 2x'" />
                {{ contactLinkContent(type) }}
              </a>
              &nbsp;
            </template>
          </p>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: "accountExclusion",
  data () {
    return {
      principal_title: 'Exclusão da conta',
      principal_subtitle: '',
      principal_text: `<p>Caso você apenas tenha criado uma conta, mas não esteja participando de nenhuma comemração, você pode ir nas configurações do próprio app e pedir para "Excluir minha conta" ou entrar em contato com nosso suporte nos canais abaixo. O processo leva até 24h para ser concluido.</p>
                      <p>Se você estiver vinculado a uma turma, nós precisamos fazer primeiro uma verificação junto a sua comissão, para entender a sua situação. Então a solicitação de exclusão é feita através do nosso suporte.</p>
                      <h4>Porque nós precisamos saber disso?</h4>
                      <p>Quando você inicia o processo, você assina o estatuto junto com a sua comissão e se compromente com o processo de arrecadação.</p>
                      <p>Nossa verificação protege você de cobranças indevidas e ajuda a sua comissão e os demais participantes a terem mais controle jurídico.</p>
                      <h4>Essas pendências independem de excluir meus dados da Heppi?</h4>
                      <p>Não. O processo de exclusão fica apenas um pouco mais burocrático e pode demorar até 1 semana para ser concluído.</p>
                      <p>Você só precisa entrar em contato com o nosso suporte, por um dos canais abaixo e solicitar a exclusão. Nós vamos fazer a verificação e te manter informado durante o processo.</p>`,
      contacts: [
        {
          type: ['Whatsapp'],
          title: '(81) 9 8181-2327',
          text: 'Nos temos um whatsapp. Manda uma mensagem pra nós',
          link: ''
        },
        {
          type: ['E-mail'],
          title: 'fala@heppi.com.br',
          text: 'A gente também responde e-mails rapidinho. =)',
          link: ''
        }
      ]
    }
  },
  computed: {
    ...mapGetters(['getSingleton', 'whatsappLink', 'image2x', 'baseImgUrl'])
  },
  methods: {
    numberPhone (phone) {
      return phone.replace(/[)(-. ]/g, '')
    },
    contactImage (type, negative) {
      switch (type) {
        case 'E-mail':
          return (negative) ? 'email_negative.png' : 'email.png'
          break
        case 'Telefone':
          return (negative) ? 'phone_negative.png' : 'phone.png'
          break
        case 'Whatsapp':
          return (negative) ? 'whatsapp_negative.png' : 'whatsapp.png'
          break
        case 'Endereço':
          return (negative) ? 'address_negative.png' : 'address.png'
          break
        default:
          return (negative) ? 'chat_negative.png' : 'chat.png'
      }
    },
    contactLink (type, link, title) {
      switch (type) {
        case 'E-mail':
          return 'mailto:' + title
          break
        case 'Telefone':
          return 'tel:0' + this.numberPhone(title)
          break
        case 'Whatsapp':
          return this.whatsappLink
          break
        case 'Endereço':
          return link
          break
        default:
          return link
      }
    },
    contactLinkContent (type) {
      switch (type) {
        case 'E-mail':
          return 'Envia um e-mail'
          break
        case 'Telefone':
          return 'Liga'
          break
        case 'Whatsapp':
          return 'Fala no Whatsapp'
          break
        case 'Endereço':
          return 'Vê no mapa'
          break
        default:
          return 'Fala com a gente'
      }
    }
  },
  created () {
    if (this.getSingleton('use_terms')) {
      for (let key in this.getSingleton('use_terms')) {
        this[key] = this.getSingleton('use_terms')[key]
      }
    }
  }
}
</script>
