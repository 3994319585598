<template>
  <main>
    <div class="container">
      <h1 v-html="principal_title"></h1>
      <p class="subtitle" v-if="principal_subtitle" v-html="principal_subtitle"></p>
      <div class="text-left" v-html="principal_text"></div>
    </div>
  </main>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: "useTerms",
  data () {
    return {
      principal_title: 'Termos de Uso',
      principal_subtitle: '',
      principal_text: `<p>Os serviços oferecidos pela <b>HEPPI DESENVOLVIMENTO DE SISTEMAS LTDA</b>, doravante denominada <b>“HEPPI”</b>, inscrita no CNPJ/MF sob o n. 38.661.528/0001-84, com sede na Avenida Governador Agamenon Magalhães, n. 2939, Empresarial International Business Center, Recife/PE, CEP: 52.021-220, como a utilização das ferramentas tecnológicas contidas no <b>APLICATIVO HEPPI</b> (<b>“APLICATIVO”</b>), são regidos por estes Termos de Uso, bem como pela legislação específica aplicável.</p>
                      <p>A utilização dos serviços implica na mais alta compreensão, vinculação e expressa aceitação do <b>USUÁRIO</b>. Ao fazer uso dos serviços oferecidos, o <b>USUÁRIO</b> concorda em respeitar e seguir todas as diretrizes dispostas neste Termo.</p>
                      <p>Este documento poderá sofrer alterações periódicas, seja por questões legais, necessidades do serviço ou decisões estratégicas da <b>HEPPI</b>. O <b>USUÁRIO</b> será notificado, pelos meios de contato por ele fornecidos no cadastro, de quaisquer modificações ocorridas, para que novamente expresse a sua concordância.</p>
                      <h3>OBJETO</h3>
                      <p>1. Com base nas disposições destes Termos de Uso, o <b>APLICATIVO</b> <b>HEPPI</b> tem por objetivo fornecer ao <b>USUÁRIO</b>:</p>
                      <p style="margin-left: 30px">1.1. Ferramenta para arrecadação de proventos, visando a realização da Formatura;</p>
                      <p style="margin-left: 30px">1.2. Gestão de pagamentos a fornecedores, negociação e cobrança de dívidas, acompanhamento e prestação de contas das atividades da Formatura;</p>
                      <p style="margin-left: 30px">1.3. Emissão de relatórios financeiros detalhados para a devida prestação de contas da Comissão.</p>
                      <p>2. A <b>HEPPI</b> busca auxiliar, não somente na arrecadação do montante necessário para a sua festividade, mas também fornecer ao <b>USUÁRIO</b> um ambiente onde é possível viabilizar as demais atividades relacionadas à formatura, como a viagem da turma, venda de senhas avulsas, ingressos para pré-eventos, rifas, entre outros.</p>
                      <h3><b>APLICATIVO</b></h3>
                      <p>3. O <b>APLICATIVO</b> funciona normalmente 24 (vinte e quatro) horas por dia, porém podem ocorrer pequenas interrupções de forma temporária para ajustes, manutenção, mudança de servidores, falhas técnicas ou por força maior, que podem deixá-lo indisponível por tempo limitado.</p>
                      <p>4. Em caso de manutenções que exigirem um tempo maior, a <b>HEPPI</b> irá informar previamente ao <b>USUÁRIO</b> da necessidade e do tempo previsto em que o <b>APLICATIVO</b> ficará offline.</p>
                      <h3>CADASTRO E LOGIN</h3>
                      <p>5. O <b>USUÁRIO</b> poderá realizar cadastro e login no <b>APLICATIVO</b> a partir de conta vinculada ao Google, à Apple ou através de um e-mail.</p>
                      <p>6. Para efetivação do cadastro, é necessário o fornecimento de telefone e email do <b>USUÁRIO</b>.</p>
                      <p>7. No momento da emissão de qualquer boleto via <b>APLICATIVO</b>, será necessário registrar o CPF do <b>USUÁRIO</b>.</p>
                      <p>8. O <b>USUÁRIO</b> se obriga a apresentar informações verdadeiras e corretas, isentando a <b>HEPPI</b> de quaisquer responsabilidades por eventual falsa declaração.</p>
                      <p>9. Uma vez efetivado o cadastramento do <b>USUÁRIO</b>, este poderá ingressar em grupo da sua <b>TURMA</b>, tendo acesso às informações pertinentes e demais funcionalidades do <b>APLICATIVO</b>.</p>
                      <p>10. Durante o processo de cadastramento, o <b>USUÁRIO</b> poderá especificar como deseja ser chamado.</p>
                      <h3>EXCLUSÃO OU BLOQUEIO DE CONTA</h3>
                      <p>11. Em caso de desistência, referente aos serviços prestados pela <b>HEPPI</b>, sua conta será excluída, após sanadas as pendências oriundas da “Política de Desistência da Heppi”.</p>
                      <p>12. Na hipótese da cláusula anterior, caso verificada a existência de débitos ou qualquer outra pendência referente à “Política de Desistência da Heppi”, o <b>USUÁRIO</b> terá sua conta suspensa, até que se tenha encerrado o contrato com a <b>TURMA</b>.</p>
                      <p>13. Caso constatada alguma atividade que fuja das disposições deste Termo, a <b>HEPPI</b> poderá suspender o acesso do <b>USUÁRIO</b>, bloqueando a conta, se necessário.</p>
                      <h3>ATUALIZAÇÃO E ALTERAÇÃO DE DADOS</h3>
                      <p>14. O <b>USUÁRIO</b> se compromete em manter seus dados cadastrais atualizados, alterando-os no <b>APLICATIVO</b> caso necessário.</p>
                      <p>15. O <b>USUÁRIO</b> poderá alterar seus dados cadastrais pelo <b>APLICATIVO</b>.</p>
                      <p>16. Em caso de alteração de telefone, a solicitação deverá ser feita diretamente pelos canais de Suporte, disponibilizados no aplicativo. A referida modificação será analisada pelo Suporte em até 48 (quarenta e oito) horas.</p>
                      <h3>PROPRIEDADE INTELECTUAL E NÃO TRANSFERÊNCIA DE TECNOLOGIA</h3>
                      <p>17. A <b>HEPPI</b>, única e exclusiva proprietária dos direitos de propriedade intelectual relativos ao <b>APLICATIVO</b>, licenciará o uso do software para execução dos serviços descritos neste Termo, não ocorrendo a transferência, a qualquer título, da tecnologia envolvida.</p>
                      <p>18. O <b>USUÁRIO</b> se obriga a zelar pelo conceito da marca de titularidade da <b>HEPPI</b>, abstendo-se de qualquer ato ou omissão que direta ou indiretamente cause ou possa causar descrédito, desprestígio ou diminuição do valor da referida marca.</p>
                      <h3>PROTEÇÃO E PRIVACIDADE DE DADOS</h3>
                      <p>19. As Partes se comprometem a cumprir todas as leis, regras e regulamentos aplicáveis aos dados pessoais eventualmente tratados em razão da execução das obrigações assumidas por elas, incluindo, mas não se limitando, à Lei 13.709/18 (Lei de Proteção d e Dados Pessoais - LGPD).</p>
                      <p>20. De acordo com os conceitos jurídicos elencados no art. 5o da Lei Geral de Proteção de Dados, as partes reconhecem a <b>HEPPI</b> enquanto CONTROLADORA dos dados.</p>
                      <p>21. As Partes deverão utilizar os dados pessoais recebidos em função desta relação jurídica somente para a finalidade aqui ajustada e em casos de legítimo interesse, excetuando-se o uso dos dados pessoais anonimizados.</p>
                      <p>22. As Partes se obrigam a não compartilhar os dados pessoais com terceiros, salvo com autorização prévia e expressa da outra parte e dos titulares.</p>
                      <p>23. Em caso de incidente de vazamento de dados pessoais, a Parte que sofreu o vazamento deverá enviar comunicação à outra, por escrito, contendo, no mínimo, as seguintes informações: data e hora do incidente; data e hora da ciência pela Parte que teve os dados vazados; relação dos tipos de dados afetados pelo incidente; relação de titulares afetados pelo incidente; e indicação de medidas que estiverem sendo tomadas para reparar eventuais danos e evitar novos incidentes.</p>
                      <h3>DECLARAÇÕES DAS PARTES E DISPOSIÇÕES GERAIS</h3>
                      <p>24. Nenhuma das partes poderá ser responsabilizada por inadimplementos de suas obrigações constantes deste Termo quando estes forem causados por casos fortuitos ou de força maior, ou seja, por circunstâncias além do controle das partes, assim entendidos os fenômenos da natureza, incêndios, inundações, terremotos, estados de guerra, acidentes, dentre outros eventos que possuam as mesmas características.</p>
                      <p>25. A nulidade de qualquer cláusula destes Termos somente afetará a cláusula em questão, permanecendo válidas todas as demais disposições contratuais.</p>
                      <p>26. O não exercício por qualquer Parte de qualquer direito relacionado ao presente instrumento não implica novação de seus termos, tampouco renúncia ou desistência de direito.</p>
                      <p>27. As Partes não poderão ceder ou transferir, total ou parcialmente, os direitos e obrigações decorrentes deste instrumento, sem prévio e expresso consentimento a ser formalizado por escrito por todas as Partes.</p>
                      <p>28. As partes reputam válidas para todos os fins legais, inclusive para a execução de título extrajudicial, às assinaturas firmadas em meio digital, conforme disposições do art. 10, § 2o da Medida Provisória n. 2.200-2, de 24 de agosto de 2001.</p>
                      <h3>FORO DE ELEIÇÃO</h3>
                      <p>29. As partes elegem o foro da Comarca de Recife-PE, para dirimir qualquer dúvida ou julgar qualquer litígio oriundo desta relação.</p>
                      <br /><br />
                      <p class="text-center"><b><b>HEPPI</b> DESENVOLVIMENTO DE SISTEMAS LTDA</b><br />CNPJ/MF nº 38.661.528/0001-84</p>`
    }
  },
  computed: {
    ...mapGetters(['getSingleton'])
  },
  created () {
    if (this.getSingleton('use_terms')) {
      for (let key in this.getSingleton('use_terms')) {
        this[key] = this.getSingleton('use_terms')[key]
      }
    }
  }
}
</script>
